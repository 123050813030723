<style lang="scss" scoped>
.entity {
  transition: all 0.2s;
  display: inline-block;
  //border:2px solid #fff;
  cursor: pointer;
  position: relative;
  margin-bottom: 15px;
  //height: 210px;
  width: 100%;
  .name {
    font-size: 90%;
    padding: 5px;
    word-wrap: break-word;
  }
  &:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }

  .img {
    width: 100%;
    height: 170px;

    display: inline-block;
    background-position: center center;
    background-size: cover;
  }
  .img-box {
    width: 100%;
    img {
      display: block;
      width: 100%;
    }
  }
  .video {
    width: 100%;
    height: 170px;
    display: inline-block;
    .playingcontainer {
      width: 100%;
      height: 100%;
      position: relative;
      background-color: #eee;

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50px !important;
        height: 50px !important;
      }
    }
    video {
      width: 100%;
      height: 100%;
    }
  }
}
</style>

<template>
  <div class="col-xl-3 col-lg-4 col-md-6">
    <div class="entity" @click="clicked">
      <!-- Image -->
      <!--<div class="img" v-if="entity.type == 'image'" :style="`background-image:url(${entity.url})`"></div>-->
      <div class="img-box" v-if="entity.type == 'image'">
        <img :src="preview ? `${entity.url}/250xauto` : entity.url" :alt="entity.name" />
      </div>

      <!-- Video -->

      <div class="video" v-if="entity.type == 'video'">
        <video controls v-if="!preview || playing">
          <source :src="entity.url" />
        </video>
        <div class="playingcontainer" @click="onPlaying" v-if="preview && !playing">
          <Icon :path="mdiPlayCircle" :width="200" :height="200" />
        </div>
      </div>

      <div class="name">{{ entity.name }}<br /></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { mdiPlayCircle } from '@mdi/js';

export default defineComponent({
  props: {
    entity: { type: Object, required: true },
    preview: { type: Boolean, default: false },
  },
  emits: ['selected'],
  setup(props, { emit }) {
    const playing = ref(false);
    const clicked = () => {
      emit('selected');
    };

    const onPlaying = () => {
      playing.value = true;
    };
    return {
      clicked,
      onPlaying,
      playing,
      mdiPlayCircle,
    };
  },
});
</script>
