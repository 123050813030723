<style lang="scss">
#backend {
  .medialib {
    //border:1px solid blue;

    .controls-box {
      //border:1px solid red;
      padding-top: 5px;
      h3 {
        font-family: 'Helvetica Neue LT W05 56 Italic';
        letter-spacing: 2px;
        padding: 10px 0;
        border-top: 5px solid #000;
        font-size: 100%;
      }
    }
  }

  .previewimg {
    img {
      display: block;
      width: 100%;
    }
  }
  .entity-container {
    display: flex;
    flex-wrap: wrap;

    .library {
      //border:1px solid blue;
      .entity {
        border: 3px solid #fff;
      }
    }
    .selected {
      .entity {
        border: 3px solid $coral;
      }
    }
  }
}
.btn-outer-upload-backend {
  //border:1px solid red !important;
  margin-bottom: 25px;
  button {
    border: 0;
    background: $grey;
    padding: 10px 15px;
  }
}
</style>

<template>
  <div class="container-fluid medialib" :class="{ selectable: multiselect || select }">
    <div class="row">
      <div class="col-12">
        <div class="headline-h1">
          <h1>Übersicht</h1>
          <div class="line"><span>Medien Bibliothek</span></div>
        </div>
      </div>
      <div class="col-12">
        <div class="btn-outer-upload-backend">
          <MultiUpload media @finished="onUploaded" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <MediaLibPagination v-model="page" :pages="pages" />
      </div>
    </div>
    <div class="row">
      <div class="entity-container" :class="library ? 'col-9' : 'col-12'">
        <div class="row">
          <MediaLibEntity preview :class="{ library, selected: isSelected(index), selectable: multiselect || select }" :entity="entity" @selected="onSelect(index)" :key="entity.id" v-for="(entity, index) in filteredMedia" />
        </div>
      </div>
      <div class="controls col-3" v-if="library && active">
        <div class="controls-box sticky-top">
          <h3>Details Media Datei</h3>
          <div class="form-group"><span class="label-input">Name </span> <input type="text" v-model="active.name" /></div>
          <!-- <div class="form-group"><span class="label-input">Beschreibung </span> <input type="text" v-model="active.description" /></div> -->
          <div class="form-group"><span class="label-input">Meta </span> {{ active.meta }}</div>
          <div class="form-group"><span class="label-input">URL </span> <input type="text" v-model="active.url" disabled /></div>
          <div class="form-group"><span class="label-input">Erstellungsdatum </span> {{ formatDate(active.createdat) }}</div>
          <button class="btn-std btn-color-save" @click="save">Speichern</button>
          <button @click="deleteimage(active?.id)" class="btn-std btn-color-delete">{{ confirmdelete ? 'Wirklich löschen?' : 'Löschen' }}</button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <MediaLibPagination v-model="page" :pages="pages" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Emitter } from 'mitt';
import { defineComponent, ref, inject, computed } from 'vue';
import MediaLibEntity from './MediaLibEntity.vue';
import { useStore } from 'vuex';
import dayjs from 'dayjs';

import MultiUpload from '../../util/MultiUpload.vue';
import MediaLibPagination from './MediaLibPagination.vue';

export default defineComponent({
  components: {
    MediaLibEntity,
    MultiUpload,
    MediaLibPagination,
  },
  emits: ['confirm', 'selected', 'deselected'],
  props: {
    library: { type: Boolean, default: false },
    select: { type: Boolean, default: false },
    multiselect: { type: Boolean, default: false },
    type: { type: String, default: 'all' },
    category: { type: String, default: 'all' },
    perPage: { type: Number, default: 20 },
  },
  setup(props, { emit }) {
    const store = useStore();

    const media = computed<Array<MediaEntity>>(() => store.getters.getMediaLib);
    const selected = ref<Array<number> | number>(-1);
    const active = ref<MediaEntity>();
    const emitter = inject('emitter') as Emitter;
    const page = ref<number>(0);

    // const uploadfile = ref();

    // const upload = () => {
    //   store.dispatch('uploadCategorizedMedia', { category: props.category, file: uploadfile.value }).then(() => store.dispatch('fetchMediaLib'));
    // };

    const onUploaded = () => {
      store.dispatch('fetchMediaLib');
    };

    store.dispatch('fetchMediaLib');

    const preCategorizedMedia = computed(() => {
      let result = media.value;

      if (props.type != 'all') {
        result = media.value.filter((item: MediaEntity) => item.type == props.type);
      }

      if (props.category != 'all') {
        result = media.value.filter((item: MediaEntity) => item.category == props.category);
      }

      return result;
    });

    const pages = computed(() => Math.ceil(preCategorizedMedia.value.length / props.perPage));

    const filteredMedia = computed(() => {
      return preCategorizedMedia.value.slice(page.value * props.perPage, page.value * props.perPage + props.perPage);
    });

    const isSelected = (index: number) => {
      if (typeof selected.value == 'number') {
        return index == selected.value;
      }

      if (Array.isArray(selected.value)) {
        return selected.value.includes(index);
      }

      return false;
    };

    const formatDate = (date: Date) =>
      dayjs(date)
        .locale('de')
        .format('DD. MMMM YYYY HH:MM');

    const onSelect = (index: number) => {
      if (!props.multiselect && !props.select) {
        active.value = filteredMedia.value[index];
        selected.value = index;
        return;
      }
      if (props.multiselect) {
        if (!Array.isArray(selected.value)) {
          selected.value = [];
        }
        const arrayindex = selected.value.indexOf(index);
        if (arrayindex > -1) {
          selected.value.splice(arrayindex, 1);
          emit('deselected', filteredMedia.value[index]);
        } else {
          selected.value.push(index);
          emit('selected', filteredMedia.value[index]);
        }
      } else {
        if (selected.value == index) {
          selected.value = -1;
          emit('deselected', filteredMedia.value[index]);
        } else {
          selected.value = index;
          emit('selected', filteredMedia.value[index]);
        }
      }
    };

    const confirmSelection = () => {
      let res: Array<MediaEntity> | MediaEntity;

      if (selected.value != -1) {
        if (Array.isArray(selected.value)) {
          emit(
            'confirm',
            filteredMedia.value.filter((item: MediaEntity, index: number) => (selected.value as Array<number>).includes(index))
          );
        } else if (typeof selected.value == 'number') {
          emit('confirm', filteredMedia.value[selected.value]);
        }
      } else {
        emit('confirm', -1);
      }
    };
    emitter.on('MediaLibConfirmSelection', confirmSelection);

    const confirmdelete = ref(false);
    const deleteimage = (id: number | undefined) => {
      if (confirmdelete.value) {
        if (id) {
          store.dispatch('removeMedia', id).then(() => {
            store.dispatch('addNotification', { message: 'Media gelöscht' });
            store.dispatch('fetchMediaLib');
            active.value = undefined;
            confirmdelete.value = false;
            selected.value = -1;
          });
        }
      } else {
        confirmdelete.value = true;
        setTimeout(() => (confirmdelete.value = false), 4000);
      }
    };

    const save = () => {
      store.dispatch('updateMedia', active.value).then(() => {
        store.dispatch('addNotification', { message: 'Media aktualisiert' });
        store.dispatch('fetchMediaLib');
      });
    };

    return {
      active,
      filteredMedia,
      onSelect,
      isSelected,
      deleteimage,
      save,
      confirmdelete,
      // changeUploadfile,
      // upload,
      onUploaded,
      formatDate,
      page,
      pages,
    };
  },
});
</script>
