
import { defineComponent, ref } from 'vue';
import { mdiPlayCircle } from '@mdi/js';

export default defineComponent({
  props: {
    entity: { type: Object, required: true },
    preview: { type: Boolean, default: false },
  },
  emits: ['selected'],
  setup(props, { emit }) {
    const playing = ref(false);
    const clicked = () => {
      emit('selected');
    };

    const onPlaying = () => {
      playing.value = true;
    };
    return {
      clicked,
      onPlaying,
      playing,
      mdiPlayCircle,
    };
  },
});
