
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    modelValue: { type: Number, required: true },
    pages: { type: Number, default: 0 },
  },
  setup(props, { emit }) {
    const value = computed({
      get: () => props.modelValue,
      set: (id: any) => emit('update:modelValue', id),
    });

    const prev = () => {
      if (value.value > 0) value.value--;
    };
    const next = () => {
      if (value.value < props.pages - 1) value.value++;
    };

    const first = () => {
      value.value = 0;
    };

    const last = () => {
      value.value = props.pages - 1;
    };

    return {
      value,
      prev,
      next,
      first,
      last,
    };
  },
});
